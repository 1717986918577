import { ThemeUIStyleObject } from 'theme-ui'
import type { Theme } from 'theme-ui'
import tailwind from '@theme-ui/preset-tailwind'
import tailwindTheme from '../../tailwind.config'

const colorPallette = tailwindTheme.theme.colors

declare module "theme-ui" {
  interface Theme {
    footer?: ThemeUIStyleObject
    texts?: ThemeUIStyleObject
  }
}

const theme: Theme = {
  ...tailwind,
  
  config: {
    useColorSchemeMediaQuery: 'system',
  },
  colors: {
    text: colorPallette.black,
    background: colorPallette.white,
    primary: colorPallette.orange,
    secondary: colorPallette.neutral,
    accent: colorPallette.teal,
    muted: colorPallette.gray,
  },
  layout: {
    app: {
        position: 'relative',
        minHeight: '100vh',
    },
    
  },
  links: {
    sideNavMenu: {
      margin: '4px',
      '&:hover': {
        cursor: 'pointer',
      }
    }
  },
  buttons: {
    primary: {
      color: 'white',
      bg: 'black',
      '&:hover': {
        bg: 'text'
      }
    }
  },
  text: {
    paragraph: {
      margin: '16px',
    },
    mutedText: {
      color: 'muted',
      fontSize: '.8rem',
    },
  },
  
  /* primarily styles used for MDX + master css speck */
  styles: {
    ...tailwind.styles,
    root: {
      fontFamily:'sans-serif',
    },
    a: {
      margin: '4px',
      color: 'primary',
    },
  }
}
export default theme