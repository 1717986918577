/** @type {import('tailwindcss').Config} */

const colors = require('tailwindcss/colors')

export default {
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      gray: colors.slate[400],
      black: colors.black,
      white: colors.white,
      neutral: colors.neutral[100],
      blue: colors.blue[400],
      orange: colors.orange[300],
      teal: colors.teal[400],
    },
  },
}